import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const Separator = styled.div`
  border: none;
  border-top: 2px dashed #c8c8c8;
  margin-left: 2rem;
  margin-right: 2rem;
  height: 0px;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const ContainerSelect = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const SubmitButton = styled(Button)`
  border-radius: 41px;
  font-size: 1rem;
  width: 95%;
  margin: .5rem;
  text-transform: none;
`;

export const DialogActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
`;