import styled from 'styled-components';

export const Container = styled.div`
  padding: 0;
  height: 100%; 
  background-color: white;
`;

export const GhostFooter = styled.div`
  background-color: white;
  height: 77px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;